import { Route, Routes } from 'react-router'

import Footer from 'src/components/Main/Footer'
import Home from 'src/components/Main/Home'
import HowItWorks from 'src/components/Main/HowItWorks'
import NavBar from 'src/components/Main/NavBar'

export default function Main() {
  return (
    <>
      <Routes>
        <Route path="*" element={<NavBar/>} />
      </Routes>
      <Routes>
        <Route path="/how-it-works" element={<HowItWorks/>}/>
        <Route path="*" element={<Home/>}/>
      </Routes>
      <Routes>
        <Route path="*" element={<Footer/>} />
      </Routes>
    </>
  )
}

