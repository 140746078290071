import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'

import { useEffect, useState } from 'react'
import { Alert, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'

const contractedHoursOption = [
  {label: '36', value: 36},
  {label: '40', value: 40},
  {label: '48', value: 48}
]

function Home() {

  const [agency, setAgency] = useState(null)
  const [agencies, setAgencies] = useState([])
  const [contractedHours, setContractedHours] = useState({label: '36', value: 36})
  const [grossPay, setGrossPay] = useState(undefined)
  const [isLocal, setIsLocal] = useState(false)
  
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(undefined)

  const onClickCalculate = () => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/calculateBillRate`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          agencyId: agency.value,
          contractedHours: contractedHours.value,
          grossPay: grossPay,
          isLocal: isLocal
        })
      }
    )
    .then(res => res.json())
    .then(json => {
      setLoading(false)
      setResults(json["result"])
    })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/listAgencies`)
    .then(res => res.json())
    .then(json => {
      setAgencies(json["agencies"].map((item) => ({label: item.name, value: item.id})))
    })
  },[])

  const isButtonEnabled = !!agency && !!contractedHours && !!grossPay

  const filterOptions = (
    candidate, input
  ) => {
    if (input) {
      return candidate.label.toLowerCase().includes(input.toLowerCase()) || candidate.value === '0';
    }
    return true;
  };

  return (
    <Container fluid style={{paddingBottom: "60px"}}>
      <Row>
        <Col lg={{span: 6, offset: 3}}>
          <Link to="/">
            <img
              alt=""
              src="/images/logo.png"
              style={{width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '40px'}}
            />
          </Link>
          <Form>
            <p style={{fontStyle: "italic"}}>Ready to have pay transparency? Enter the information below to get started!</p>
            <Link to="/how-it-works">How It Works?</Link>
            <div style={{height: '20px'}}></div>
            <Form.Group className="mb-3" controlId="billRateCalculator">
              <Form.Label style={{fontWeight: 'bold'}}>Select Agency</Form.Label>
              <Select
                defaultValue={agency}
                value={agency}
                onChange={(value) => setAgency(value)}
                label="Agency"
                options={agencies}
                className={styles.picker}
                filterOption={filterOptions}
              />
              <Form.Label style={{fontWeight: 'bold'}}>Select Contracted Hours</Form.Label>
              <Select
                defaultValue={contractedHours}
                value={contractedHours}
                onChange={(value) => setContractedHours(value)}
                label="Contracted Hours"
                options={contractedHoursOption}
                className={styles.picker}
              />
              <Form.Label style={{fontWeight: 'bold'}}>Gross Pay</Form.Label>
              <Form.Control type="number" placeholder="e.g., 2500" onChange={(e) => setGrossPay(e.target.value)}/>
              <Form.Text className="text-muted">
                Total amount in dollars per week
              </Form.Text>
              <div style={{height: '10px'}}></div>
              <Form.Label style={{fontWeight: 'bold'}}>Local Contract</Form.Label>
              <Form.Check // prettier-ignore
                type="radio"
                checked={isLocal}
                onChange={() => setIsLocal(true)}
                label={`Yes`}
              />
              <Form.Check
                type="radio"
                checked={!isLocal}
                label={`No`}
                onChange={() => setIsLocal(false)}
              />
            </Form.Group>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {!loading && <Button variant="success" onClick={onClickCalculate} disabled={!isButtonEnabled}>
                Calculate Rate
              </Button>}
              {loading && <Spinner animation="border" role="status" color="success">
                <span className="visually-hidden">Loading...</span>
              </Spinner>}
            </div>
            { results &&
              <>
                <div style={{height: "20px"}}></div>
                <p>*Disclaimer: The bill rate calculator is a rough estimate based on public data. Individual contracts may vary.</p>
                <p>*The clinician rate is a blended rate of both stipend and hourly rate if the contract is a travel contract. If the contract quoted is a local contract, then the clinician rate is the full hourly rate.</p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Low</th>
                      <th>Avg</th>
                      <th>High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Clinician Pay Rate (Stipend + Hourly Rate)</td>
                      <td>${results.low.clinicianPayRate}</td>
                      <td>${results.medium.clinicianPayRate}</td>
                      <td>${results.high.clinicianPayRate}</td>
                    </tr>
                    <tr>
                      <td>Agency Gross Profit (Hourly)</td>
                      <td>${results.low.agencyGrossProfitHourly}</td>
                      <td>${results.medium.agencyGrossProfitHourly}</td>
                      <td>${results.high.agencyGrossProfitHourly}</td>
                    </tr>
                    <tr>
                      <td>Bill Rate</td>
                      <td>${results.low.billRate}</td>
                      <td>${results.medium.billRate}</td>
                      <td>${results.high.billRate}</td>
                    </tr>
                  </tbody>
                </Table>
                <Alert variant="success">
                <Alert.Heading>Bill Rate Insight</Alert.Heading>
                <p>
                  We estimate your bill rate is <b>${results.insight.estimate}</b> per hour. You should negotiate an increase of between <b>${results.insight.hourlyLow}</b> - <b>${results.insight.hourlyHigh}</b> per hour, resulting in an increase of between <b>${results.insight.weeklyLow}</b> - <b>${results.insight.weeklyHigh}</b> per week.
                </p>
              </Alert>
              </>
            }
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;