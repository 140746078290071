import { Navbar } from 'react-bootstrap'


export default function NavBar() {
  return (
    <header>
      <Navbar variant="light">
          <Navbar.Brand href="/">
          </Navbar.Brand>
      </Navbar>
    </header>
  )
}