import { createRoot } from 'react-dom/client'
import {
  RecoilRoot
} from 'recoil'

import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <App/>
    </RecoilRoot>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
