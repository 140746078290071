import { Navbar } from 'react-bootstrap'


export default function Footer() {
  return (
    <footer>
      <Navbar variant="light" fixed="bottom" bg="light" style={{padding: '10px 20px'}}>
        <p>Have suggestions? Contact us at <a href="mailto:billratecalculator@gmail.com">billratecalculator@gmail.com</a></p>
      </Navbar>
    </footer>
  )
}