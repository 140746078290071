import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function HowItWorks() {
    return (
        <Container style={{paddingBottom: '50px'}}>
            <Row className="mb-3">
                <Col>
                    <Link to="/">
                        <img
                        alt=""
                        src="/images/logo.png"
                        width="250"
                        style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '40px'}}
                        />
                    </Link>
                    <h1>How It Works</h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                A Bill Rate is the rate a facility pays a staffing agency for each hour a clinician works at that facility. This rate isn’t the same as the pay rate, which is the amount the travel nurse actually receives, which we have broken down above. Bill rates can vary by hospital in the same city. They can even vary by agency for the same position at the same hospital.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>General percentage breakdown of a Bill Rate:</Card.Title>
                            <Card.Text>
                                <strong>VMS/MSP fee:</strong> 2-6%<br />
                                <strong>Interest on Invoices (for Line of Credit/Factored Receivables):</strong> 1-3%<br />
                                <strong>Amount for Agency to pay clinician/salaries/insurance/etc:</strong> 91-97%<br />
                                <strong>Gross Profit for Agency (varies per agency):</strong> 20-35%
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                Gross profit is the remaining percentage the agency makes after other fees and the traveler’s rate are covered. Typically this ranges from 20-35%. The other fees can range from insurance to recruiter pay to office supplies. Agencies typically have 8-15% net profit margins after the nurse receives their earnings and all the bills have been covered from the original bill rate. Most agencies try to take as low a margin as possible, but they must earn some money, as most companies cannot afford to operate for free.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Local contract - why do we ask this?</Card.Title>
                            <Card.Text>
                            The difference between a local contract and travel contract is the nontaxable stipend. Local contracts only pay the hourly rate because the clinician resides within the facility's mileage rule. Therefore, the local traveler only receives the hourly rate as they do not meet requirements for claiming duplicate living expenses.                             </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Direct contract</Card.Title>
                            <Card.Text>
                                MSPs (managed service providers) and VMSs (vendor management systems) are a big influencer in bill rates. These are the middlemen between most of the hospitals and staffing agencies. A large benefit to agencies using MSPs is that they can have access to many facilities, as opposed to attempting to get direct contracts with each facility individually. This can therefore directly affect the amount a traveler makes on their bill rate. If the traveler is with an agency that has a direct contract with the hospital, then the bill rate may be better.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Gross Pay vs. Net Pay</Card.Title>
                            <Card.Text>
                                Gross Pay is the amount of money that is estimated to be paid to the traveler before taxes or insurance. Essentially, gross pay is the non-taxed amount for a contract and net pay is the taxed amount. Since everyone has different needs and expenses, gross pay is more widely used to project the amount a traveler will make at a given contract.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default HowItWorks;
